import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

import "../../assets/styles/shared/drop-down.scss";

const DropDown = (props) => {
  const {
    label,
    value,
    defaultValue,
    options,
    onChange,
    className,
    placeholder,
    size = "middle",
    showItemsCount = false,
    optionClassName = ""
  } = props;

  const classNames = ["drop-down"];
  if (className) {
    classNames.push(className);
  }


  return (
    <div key={defaultValue} className={classNames.join(" ")}>
      {label && <div className="drop-down-component--label">{label}</div>}
      <Select
        onChange={(val, option) => onChange(val, option)}
        placeholder={placeholder}
        value={value}
        className="drop-down-component"
        size={size}
        virtual={false}
      >
        {
          (options || []).map(option => {
            let optionValue = "";
            let optionLabel = "";
            let onClick = null;
            if (typeof option === "object") {
              optionValue = option.value;
              onClick = option.onClick || null;
              optionLabel = option.label || option.value;

              if (option.icon) {
                const { icon: Icon } = option;
                optionLabel = (
                  <div className="drop-down--component--label-row">
                    <div className="drop-down--component--label-row--image">
                      <Icon />
                    </div>
                    <div className="drop-down--component--label-row--label">
                      {option.label || option.value}
                    </div>
                  </div>
                )
              }
            }
            else {
              optionValue = option;
              optionLabel = option;
            }

            return (
              <Select.Option key={`option:${label}:${optionValue}`} value={optionValue}>
                <div className={`drop-down--component--wrapper ${optionClassName}`} onClick={onClick}>
                  <span
                    className={`${showItemsCount ? "drop-down--component--wrapper--title" : ""}`}
                  >
                    {optionLabel}
                  </span>
                  <span className="drop-down--component--wrapper--items-count">{showItemsCount && option.itemsCount}</span>
                </div>
              </Select.Option>
            )
          })
        }
      </Select>
    </div>
  )
};

DropDown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  optionClassName: PropTypes.string,
  showItemsCount: PropTypes.bool
};

export default DropDown;
