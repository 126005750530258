import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { Pagination as AntdPagination } from "antd";

import useDimensions from "../../hooks/useDimensions";

const Pagination = ({
  total,
  pageSize,
  current,
  arrowClassName,
  containerClassName,
  ...rest
}) => {
  const [paginationRef, { width }] = useDimensions({ liveMeasure: false });
  const location = useLocation();

  const maxPage = Math.ceil(total / pageSize);
  const isMobile = width <= 525;

  const renderArrows = (page, type, originalElement) => {
    const prevAttribute = {};
    const nextAttribute = {};

    if (current !== 1) {
      prevAttribute.rel = "prev";
    }
    if (current !== maxPage) {
      nextAttribute.rel = "next";
    }

    if (type === "prev") {
      const classNames = ["ant-pagination-item-link pagination-arrow-left"];
      if (arrowClassName) {
        classNames.push(arrowClassName);
      }

      return (
        <a
          href={`${location.pathname}${
            page && +page !== 1 ? `?page=${page}` : ""
          }`}
          onClick={(e) => e.preventDefault()}
          className={classNames.join(" ")}
          data-testid="lant-pagination-btn-prev"
          {...prevAttribute}
        >
          &#8592;
        </a>
      );
    }
    if (type === "next") {
      const classNames = ["ant-pagination-item-link pagination-arrow-right"];
      if (arrowClassName) {
        classNames.push(arrowClassName);
      }

      return (
        <a
          className={classNames.join(" ")}
          href={`${location.pathname}?page=${page}`}
          onClick={(e) => e.preventDefault()}
          data-testid="lant-pagination-btn-next"
          {...nextAttribute}
        >
          &#8594;
        </a>
      );
    }

    return {
      ...originalElement,
      props: {
        ...originalElement.props,
        rel: "follow",
        href:
          page === 1
            ? `${location.pathname}`
            : `${location.pathname}?page=${page}`,
        onClick: (e) => e.preventDefault(),
      },
    };
  };

  return (
    <div
      ref={paginationRef}
      data-testid="lant-pagination"
      className={containerClassName}
    >
      <AntdPagination
        {...rest}
        total={total}
        pageSize={pageSize}
        current={current}
        itemRender={renderArrows}
        showSizeChanger={false}
        responsive={isMobile}
        showLessItems={isMobile}
      />
    </div>
  );
};

Pagination.propTypes = {
  containerClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  total: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  current: PropTypes.number,
};

export default Pagination;
